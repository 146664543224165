import * as types from "../constants";

const initialTasksHeadCells = [
	{ id: "PracticeMethodType", alignment: "left", active: true },
	{ id: "FolderCode", alignment: "left", active: true },
	{ id: "FolderTask", alignment: "left", active: true },
	{ id: "DevTechName", alignment: "left", active: true },
	{ id: "TaskBeginDate", alignment: "left", active: true },
	{ id: "Description", alignment: "left", active: true },
	{ id: "InitialQuantity", alignment: "right", active: true },
	{ id: "ProgressPercentage", alignment: "left", active: true },
	{ id: "WorkedQuantity", alignment: "right", active: true },
	{ id: "TaskStatus", alignment: "left", active: true },
];

export default function tasksHeadCellsReducer(
	state = initialTasksHeadCells,
	actions
) {
	switch (actions.type) {
		case types.SET_TASKS_HEAD_CELLS:
			return [...actions.payload];
		default:
			return state;
	}
}
