import { Button } from "@material-ui/core";
import { _arrayBufferToBase64 } from "api/files";
import React from "react";
import { useTranslation } from "react-i18next";
import { useCustomSnackbar } from "./Snackbar";

function ChooseFile({ fileToUpload, setFileToUpload }) {
	const { t } = useTranslation();
	const snackbar = useCustomSnackbar();

	return (
		<Button color="primary" variant="contained" component="label">
			{fileToUpload?.Name || t("ChooseFile")}
			<input
				id="document-upload-file"
				type="file"
				accept=".png,.jpg,.jpeg,.pdf"
				onChange={(event) => {
					const [file] = event.target.files;

					if (file) {
						var reader = new FileReader();
						reader.readAsArrayBuffer(file);
						reader.onload = function (evt) {
							setFileToUpload({
								...fileToUpload,
								Name: file.name,
								Bytes: _arrayBufferToBase64(evt.target.result),
							});
						};
						reader.onerror = function () {
							snackbar.showError(t("ErrorUploadDocument"));
							console.log("error reading file");
						};
					}
				}}
				hidden
			/>
		</Button>
	);
}

export default ChooseFile;
